<template>
  <div class="landing-page">
    <header>
      <nav class="navbar">
        <a href="/"> <img src="@/assets/logo.jpg" alt="Logo" class="logo" /></a>
        <ul class="menu">
          <li><a href="/">Home</a></li>
          <li><a href="pricing" v-if="false">Pricing</a></li>
          <li><a href="free-subtitle">Free Subtitles</a></li>
        </ul>
      </nav>
    </header>

    <main>
      <section class="hero">
        <h1>Welcome to Joe YouTube Subtitle</h1>
        <p>
          Generate and view subtitles for your favorite YouTube videos easily.
        </p>
      </section>

      <section class="video-list">
        <h2>Free Subtitle Videos</h2>
        <table class="video-table">
          <thead>
            <tr>
              <th>Video</th>
              <th>Watch</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(video, index) in freeVideos" :key="index">
              <td>
                <img
                  :src="getThumbnail(video.url)"
                  alt="Thumbnail"
                  class="thumbnail"
                />
                <!-- <p>{{ getVideoTitle(video.url) }}</p> -->
              </td>
              <td>
                <a
                  :href="`/subtitle-view?accessId=${video.access_url}&orderId=${
                    video.order_id
                  }&videoId=${getYouTubeVideoId(video.url)}`"
                  target="_blank"
                  class="watch-link"
                  >Watch</a
                >
              </td>
            </tr>
          </tbody>
        </table>
        <div class="more-link">
          <a href="/free-subtitle">See more free subtitle videos</a>
        </div>
      </section>
    </main>

    <footer class="footer">
      <p>
        Made by
        <a href="https://x.com/JoeSetthawut">Joe Setthawut</a>
      </p>
    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      freeVideos: [],
    };
  },
  methods: {
    getYouTubeVideoId(url) {
      const match = url.match(
        /(?:https?:\/\/)?(?:www\.)?youtube\.com\/.*[?&]v=([^&]+)/
      );
      return match ? match[1] : null;
    },
    async fetchFreeVideos() {
      try {
        const response = await fetch(
          `${process.env.VUE_APP_API_URL}/api/free-subtitle?page=1&limit=9`,
          {
            method: "GET",
          }
        );
        const data = await response.json();
        this.freeVideos = data.subtitles;
      } catch (error) {
        console.error("Error fetching videos:", error);
      }
    },
    getThumbnail(url) {
      const videoId = new URL(url).searchParams.get("v");
      return `https://img.youtube.com/vi/${videoId}/0.jpg`;
    },
    getVideoTitle(url) {
      const videoId = new URL(url).searchParams.get("v");
      return `YouTube Video ${videoId}`;
    },
  },
  created() {
    this.fetchFreeVideos();
  },
};
</script>

<style scoped>
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.logo {
  height: 50px;
}
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #282828;
}

.navbar ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navbar li {
  margin-left: 20px;
}

.navbar a {
  text-decoration: none;
  color: white;
  font-weight: bold;
}

.hero {
  text-align: center;
  padding: 50px;
  background-color: #f1f1f1;
  color: #333;
}

.video-list {
  margin: 40px auto;
  max-width: 800px;
  text-align: left;
}

.video-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.video-table th,
.video-table td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.thumbnail {
  width: 120px;
  height: auto;
  display: block;
  margin-bottom: 10px;
}

.watch-link {
  color: #ff0000; /* YouTube's red */
  text-decoration: none;
}

.more-link {
  text-align: center;
  margin-top: 20px;
}

.more-link a {
  color: #ff0000; /* YouTube's red */
  text-decoration: underline;
}

.footer {
  background-color: #282828;
  color: white;
  text-align: center;
  padding: 20px 0;
  margin-top: 50px;
}

.footer a {
  color: #ff0000; /* YouTube red */
  text-decoration: none;
  font-weight: bold;
}
</style>
