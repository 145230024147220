<template>
  <div class="subtitle-view-page">
    <header>
      <nav class="navbar">
        <a href="/"> <img src="@/assets/logo.jpg" alt="Logo" class="logo" /></a>
        <ul class="menu">
          <li><a href="/">Home</a></li>
          <li><a href="/pricing" v-if="false">Pricing</a></li>
          <li><a href="/free-subtitle">Free Subtitles</a></li>
        </ul>
      </nav>
    </header>

    <div class="container">
      <div class="video-container">
        <h2>Watch the Your Video</h2>

        <div id="player"></div>
        <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
        <div class="share-buttons">
          <button class="share-button facebook" @click="shareToFacebook">
            <i class="fab fa-facebook-f"></i> Share on Facebook
          </button>
          <button class="share-button twitter" @click="shareToTwitter">
            <i class="fab fa-twitter"></i> Share on Twitter
          </button>
        </div>
      </div>

      <div class="subtitle-container">
        <h3>Subtitles</h3>
        <div v-if="subtitles && subtitles.segments.length" class="subtitles">
          <div
            v-for="(subtitle, index) in subtitles.segments"
            :key="index"
            :id="'subtitle-' + index"
            :class="{ highlight: currentSubtitleIndex === index }"
          >
            {{ subtitle.text }}
          </div>
        </div>
        <div v-else-if="!errorMessage" class="no-subtitles">
          Subtitles are not available.
        </div>
      </div>
    </div>

    <footer>
      <p>
        Made by
        <a href="https://x.com/JoeSetthawut" target="_blank">Joe Setthawut</a>
      </p>
    </footer>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useHead } from "@vueuse/head";
import { useRoute } from "vue-router";
import axios from "axios";

export default {
  setup() {
    const route = useRoute();
    const videoTitle = ref("Your Video Title");
    const videoDescription = ref("Watch this amazing video!");
    const videoUrl = ref(window.location.href);

    const youtubeVideoId = computed(() => route.query.videoId);
    const orderId = computed(() => route.query.orderId);

    const youtubeThumbnail = computed(
      () =>
        `https://img.youtube.com/vi/${youtubeVideoId.value}/maxresdefault.jpg`
    );

    // Function to fetch data from the API
    const fetchVideoData = async () => {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/api/order/${orderId.value}`
        );

        // Assuming the response contains the title in response.data.title
        videoTitle.value = response.data.name;
      } catch (error) {
        console.error("Error fetching video data:", error);
        videoTitle.value = "Failed to load title";
      }
    };

    // Fetch the video data when the component is mounted
    onMounted(fetchVideoData);

    // Set Open Graph meta tags dynamically
    useHead({
      title: computed(() => `Watch: ${videoTitle.value}`),
      meta: [
        { name: "description", content: videoDescription.value },
        { property: "og:title", content: `Watch: ${videoTitle.value}` },
        { property: "og:description", content: videoDescription.value },
        { property: "og:url", content: videoUrl.value },
        { property: "og:image", content: youtubeThumbnail.value }, // YouTube thumbnail
      ],
    });

    return {
      videoTitle,
      videoDescription,
      videoUrl,
      youtubeVideoId,
    };
  },
  data() {
    return {
      player: null,
      subtitles: null,
      currentSubtitleIndex: -1,
      intervalId: null,
      videoId: this.$route.query.videoId, // To store the fetched video ID
      accessId: null,
      orderId: null,
      errorMessage: null, // To store error messages
      // videoTitle: "Dynamic Video Title",
      // videoDescription: "This is a description of the video.",
    };
  },

  computed: {
    // Generate the YouTube thumbnail URL based on the video ID in the videoUrl
    videoThumbnail() {
      const videoId = new URL(this.videoUrl).searchParams.get("v");
      return `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
    },
    // Generate your website URL that links to the specific video page
    websiteVideoUrl() {
      return `https://yourwebsite.com/video-page?video=${encodeURIComponent(
        this.videoUrl
      )}`;
    },
  },
  mounted() {
    this.initializePlayer(1);
    // this.fetchVideoData(); // Fetch video ID and subtitles from API
    // Load YouTube Iframe API
    // console.log("window.YT",window.YT)
    // if (!window.YT) {
    //   console.log("window.YTwindow.YTwindow.YT")
    // const tag = document.createElement("script");
    // tag.src = "https://www.youtube.com/iframe_api";
    // const firstScriptTag = document.getElementsByTagName("script")[0];
    // firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    // }
    // this.initializePlayer(); // Initialize the player after fetching data
    this.fetchVideoData(); // Fetch video ID and subtitles from API
    // this.initializePlayer(); // Initialize the player after fetching data
    // this.initializePlayer(2)

    if (!window.YT) {
      const tag = document.createElement("script");
      tag.src = "https://www.youtube.com/iframe_api";
      const firstScriptTag = document.getElementsByTagName("script")[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }
  },
  methods: {
    shareToFacebook() {
      const url = encodeURIComponent(this.videoUrl);
      const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
      window.open(facebookUrl, "_blank");
    },
    shareToTwitter() {
      const url = encodeURIComponent(this.videoUrl);
      const text = encodeURIComponent(this.videoTitle);
      const twitterUrl = `https://twitter.com/intent/tweet?url=${url}&text=${text}`;
      window.open(twitterUrl, "_blank");
    },
    fetchVideoData() {
      this.accessId = this.$route.query.accessId;
      this.orderId = this.$route.query.orderId;

      // Make a POST request to the API
      fetch(`${process.env.VUE_APP_API_URL}/api/video-access`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          orderId: this.orderId,
          videoAccessId: this.accessId,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          // Check if videoId and transcription are available
          if (
            data.subtitle &&
            data.subtitle.youtubeId &&
            data.subtitle.transcription
          ) {
            this.videoId = data.subtitle.youtubeId; // Assuming the response contains 'youtubeId'
            this.subtitles = data.subtitle.transcription; // Assuming the response contains 'transcription'

            this.initializePlayer(1); // Initialize the player after fetching data
          } else {
            this.errorMessage =
              "Video or subtitles not available. Please check the link or contact admin.";
          }
        })
        .finally(() => {})
        .catch((error) => {
          console.error("Error fetching video data:", error);
          this.errorMessage =
            "An error occurred while fetching data. Please contact admin.";
        });

      this.initializePlayer(1); // Initialize the player after fetching data
    },
    initializePlayer() {
      window.onYouTubeIframeAPIReady = () => {
        if (this.videoId) {
          this.player = new window.YT.Player("player", {
            height: "450",
            width: "800", // Adjust the size of the video
            videoId: this.videoId,
            events: {
              onReady: this.onPlayerReady,
            },
          });
        }
      };
    },
    onPlayerReady() {
      this.startSubtitleTracking();
    },
    startSubtitleTracking() {
      this.intervalId = setInterval(() => {
        const currentTime = this.getCurrentVideoTime();
        this.highlightCurrentSubtitle(currentTime);
      }, 900);
    },
    highlightCurrentSubtitle(currentTime) {
      this.subtitles.segments.forEach((subtitle, index) => {
        if (currentTime >= subtitle.start && currentTime <= subtitle.end) {
          this.currentSubtitleIndex = index;
          const subtitleElement = document.getElementById(`subtitle-${index}`);
          if (subtitleElement) {
            subtitleElement.scrollIntoView({
              behavior: "smooth",
              block: "nearest",
            });
          }
        }
      });
    },
    getCurrentVideoTime() {
      return this.player ? this.player.getCurrentTime() : 0;
    },
  },
};
</script>

<style scoped>
.subtitle-view-page {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #282828;
  padding: 10px 20px;
}

.logo {
  height: 50px;
}

.menu {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.menu li {
  margin-left: 20px;
}

.menu a {
  color: white;
  text-decoration: none;
  font-weight: bold;
}

.container {
  display: flex; /* Flexbox for side-by-side layout */
  margin: 20px;
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
}

.video-container {
  flex: 2; /* Adjust as needed for video size */
  margin-right: 20px; /* Space between video and subtitles */
}

.subtitle-container {
  flex: 1; /* Adjust as needed for subtitle size */
  height: 500px; /* Set a fixed height for subtitles */
  overflow-y: auto; /* Scroll for overflow */
  padding-left: 20px;
  border-left: 1px solid #ddd; /* Optional border */
}

.subtitles {
  margin-top: 20px;
}

.highlight {
  background-color: yellow; /* Highlight current subtitle */
}

.error-message {
  color: red; /* Error message color */
  margin-top: 10px;
}

.no-subtitles {
  color: #555; /* Style for no subtitles message */
  margin-top: 10px;
}

footer {
  background-color: #282828; /* Dark background for footer */
  color: #ffffff;
  text-align: center;
  padding: 20px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .container {
    flex-direction: column; /* Stack elements vertically on smaller screens */
    margin: 10px; /* Reduced margin for mobile */
  }

  .video-container {
    margin-right: 0; /* Remove right margin on mobile */
    margin-bottom: 20px; /* Add space below the video */
  }

  .subtitle-container {
    height: auto; /* Allow subtitle container to adjust height */
    padding-left: 0; /* Remove padding on mobile */
  }
}

/* Container for buttons */
.share-buttons {
  display: flex;
  gap: 1rem;
}

/* Base button styles */
.share-button {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  padding: 0.5rem 1rem;
  font-size: 0.7rem;
  font-weight: bold;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
}

/* Facebook button */
.share-button.facebook {
  background-color: #3b5998;
}

.share-button.facebook:hover {
  background-color: #2d4373;
}

/* Twitter button */
.share-button.twitter {
  background-color: #1da1f2;
}

.share-button.twitter:hover {
  background-color: #0d95e8;
}

/* Hover effect */
.share-button:hover {
  transform: translateY(-2px);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
}

/* Icon styling */
.share-button i {
  font-size: 1.2rem;
}
</style>
