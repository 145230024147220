import { createApp } from "vue";
import App from "./App.vue";
import { loadStripe } from "@stripe/stripe-js";
import { StripePlugin } from "vue-stripe";
import router from "./router";
import VueGtag from "vue-gtag";
import { createHead } from '@vueuse/head';


const stripePromise = loadStripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);

const app = createApp(App);
const head = createHead();

// Register the plugin
// app.use(VueMeta, {
//   // optional plugin options
//   refreshOnceOnNavigation: true,
// });

router.afterEach((to) => {
  document.title = to.meta.title || 'Joe Youtube Subtitle'; 
})

app.use(StripePlugin, {
  loadStripe: () => stripePromise,
});

app.use(router);

console.log(
  "VUE_APP_GOOGLE_ANALYTIC_TRACING_ID",
  process.env.VUE_APP_GOOGLE_ANALYTIC_TRACING_ID
);

app.use(VueGtag, {
  config: { id: process.env.VUE_APP_GOOGLE_ANALYTIC_TRACING_ID },
});



router.afterEach((to) => {
  // Track page view
  app.config.globalProperties.$gtag(
    "config",
    process.env.VUE_APP_GOOGLE_ANALYTIC_TRACING_ID,
    {
      page_path: to.fullPath,
    }
  );
});

app.use(head);

app.mount("#app");
