<template>
  <div class="pricing-page">
    <header>
      <nav class="navbar">
        <a href="/"> <img src="@/assets/logo.jpg" alt="Logo" class="logo" /></a>
        <ul class="menu">
          <li><a href="/">Home</a></li>
          <li><a href="pricing" v-if="false">Pricing</a></li>
          <li><a href="free-subtitle">Free Subtitles</a></li>
        </ul>
      </nav>
    </header>

    <section class="pricing-section">
      <h1>Pricing</h1>
      <div class="pricing-card">
        <h2>Standard Plan</h2>
        <p class="price">$1 per video</p>
        <p>Video length: Up to 15 minutes</p>
        <a href="subtitle-generator">
          <button class="subscribe-button">Generate Now</button></a
        >
      </div>
    </section>

    <footer class="footer">
      <p>
        Made by
        <a href="https://x.com/JoeSetthawut">Joe Setthawut</a>
      </p>
    </footer>
  </div>
</template>

<script>
export default {
  name: "PricingPage",
};
</script>

<style scoped>
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #282828;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #282828;
  padding: 10px 20px;
}

.navbar ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navbar li {
  margin-left: 20px;
}

.navbar a {
  text-decoration: none;
  color: white;
  font-weight: bold;
}

/* 
.logo {
  width: 150px;
} */

.pricing-section {
  padding: 40px;
  text-align: center;
}

h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #ff0000; /* YouTube red */
}

.pricing-card {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 30px;
  display: inline-block;
  max-width: 400px;
}

.price {
  font-size: 2em;
  color: #ff0000; /* YouTube red */
}

.subscribe-button {
  background-color: #ff0000;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.subscribe-button:hover {
  background-color: #cc0000;
}

.footer {
  background-color: #282828;
  color: white;
  text-align: center;
  padding: 20px 0;
  margin-top: 50px;
}

.footer a {
  color: #ff0000; /* YouTube red */
  text-decoration: none;
  font-weight: bold;
}

.logo {
  height: 50px;
}
</style>
