<template>
  <div class="subtitle-page">
    <header>
      <nav class="navbar">
        <a href="/"> <img src="@/assets/logo.jpg" alt="Logo" class="logo" /></a>
        <ul class="menu">
          <li><a href="/">Home</a></li>
          <li><a href="pricing" v-if="false">Pricing</a></li>
          <li><a href="free-subtitle">Free Subtitles</a></li>
        </ul>
      </nav>
    </header>

    <section class="subtitle-section">
      <h1>Generate YouTube Subtitles</h1>
      <div class="subtitle-form">
        <form @submit.prevent="handleSubmit">
          <div class="form-group">
            <label for="video-url">Step 1: YouTube Video URL</label>
            <input
              ref="videoUrlInput"
              type="text"
              id="video-url"
              v-model="videoUrl"
              required
              placeholder="Enter YouTube Video URL"
              class="input-field"
              :class="videoUrl == '' ? flash : ''"
              @blur="checkVideoUrl"
              @keyup.enter="checkVideoUrl"
            />
            <span v-if="errorMessage" class="error-message">{{
              errorMessage
            }}</span>
          </div>

          <div class="form-group">
            <label for="name">Step 2: VDO Name</label>
            <input
              type="text"
              id="name"
              v-model="name"
              required
              placeholder="Enter your vdo name"
              class="input-field"
              :disabled="stepValidation.step_1 != true || videoUrl == ''"
              :class="
                stepValidation.step_1 == true && videoUrl != '' && name == ''
                  ? flash
                  : ''
              "
            />
          </div>

          <div class="form-group">
            <label for="language">Step 3: Select Language</label>
            <select
              v-model="selectedLanguage"
              required
              class="input-field"
              :disabled="name == ''"
              :class="
                videoUrl != '' && name != '' && selectedLanguage == ''
                  ? flash
                  : ''
              "
            >
              <option value="" disabled>Select Language</option>
              <option value="en">English</option>
              <!-- <option value="th">Thai</option>
              <option value="es">Spanish</option> -->
              <!-- Add more languages as needed -->
            </select>
          </div>

          <div class="form-group">
            <label for="name">Step 4: Your Name</label>
            <input
              type="text"
              id="name"
              v-model="customerName"
              required
              placeholder="Enter your name"
              class="input-field"
              :disabled="selectedLanguage == ''"
              :class="
                videoUrl != '' &&
                name != '' &&
                selectedLanguage != '' &&
                customerName == ''
                  ? flash
                  : ''
              "
            />
          </div>

          <div class="form-group">
            <label for="email">Step 5: Your Email</label>
            <input
              type="email"
              id="email"
              v-model="email"
              required
              placeholder="Enter your email"
              class="input-field"
              :disabled="customerName == ''"
              :class="
                videoUrl != '' &&
                name != '' &&
                selectedLanguage != '' &&
                customerName != '' &&
                email == ''
                  ? flash
                  : ''
              "
            />
          </div>

          <div class="form-group">
            <label for="card-element">Step 4: Credit or Debit Card</label>
            <div id="card-element" class="card-element"></div>
            <div id="card-errors" role="alert" class="error-message"></div>
          </div>

          <button
            type="submit"
            class="submit-button"
            id="payButton"
            :disabled="isPayButtonDisabled"
          >
            Pay Now
          </button>

          <div v-if="paymentStatus">
            {{ paymentStatus }}
          </div>

          <!-- Loading indicator -->
          <div v-if="isLoading" class="loading-message">
            <div class="spinner"></div>
            <!-- Optional spinner -->
          </div>
        </form>

        <div v-if="subtitles" class="subtitles">
          <h3>
            Thank you for choosing JoeYoutubeSubtitle for your subtitle needs!
            Your Subtitles are Ready!
          </h3>
          <p>
            <a
              :href="`/subtitle-view?accessId=${subtitles.access_url}&orderId=${subtitles.order_id}`"
              target="_blank"
              class="watch-link"
              >Watch</a
            >
          </p>
        </div>
      </div>
    </section>

    <footer class="footer">
      <p>
        Made by
        <a href="https://x.com/JoeSetthawut">Joe Setthawut</a>
      </p>
    </footer>
  </div>
</template>

<script>
import { loadStripe } from "@stripe/stripe-js";

export default {
  data() {
    return {
      flash: "flash",
      stripe: null,
      elements: null,
      cardElement: null,
      clientSecret: "",
      videoUrl: "",
      selectedLanguage: "",
      name: "",
      email: "",
      customerName: "",
      paymentStatus: "",
      subtitles: "",
      stepValidation: {
        step_1: false,
        step_2: false,
        step_3: false,
        step_4: false,
        step_6: false,
        step_7: false,
        step_8: true,
      },
      errorMessage: "",
      isLoading: false,
    };
  },
  computed: {
    isPayButtonDisabled() {
      // Check if all required inputs have values
      return (
        !this.videoUrl ||
        !this.name ||
        !this.selectedLanguage ||
        !this.customerName ||
        !this.email
      );
    },
  },
  async mounted() {
    this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);
    this.elements = this.stripe.elements();
    this.cardElement = this.elements.create("card");
    this.cardElement.mount("#card-element");
  },
  methods: {
    async checkVideoUrl() {
      this.errorMessage = ""; // Reset error message
      this.name = "";

      if (this.videoUrl.trim() === "") {
        this.errorMessage = "URL cannot be empty."; // Set error message
        this.$refs.videoUrlInput.classList.add("error"); // Add error class
        return; // Exit if the input is empty
      } else {
        this.$refs.videoUrlInput.classList.remove("error"); // Remove error class if input is valid
      }
      try {
        // Call your API to check the video URL
        const response = await fetch(
          `${process.env.VUE_APP_API_URL}/api/validate-video`,
          {
            method: "POST", // or 'GET' depending on your API
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ videoUrl: this.videoUrl }), // Send the URL in the request body
          }
        );

        const data = await response.json();

        if (!response.ok) {
          // throw new Error("Network response was not ok");
          this.errorMessage = data.error; // Set error message
          this.$refs.videoUrlInput.classList.add("error"); // Add error class
          this.stepValidation.step_1 = false;
          throw new Error("Network response was not ok");
        } else {
          this.stepValidation.step_1 = true;

          this.$refs.videoUrlInput.classList.remove("error"); // Remove error class if input is valid
        }

        this.name = data.title;

        // Handle the response data (e.g., update the UI or store the data)
      } catch (error) {
        console.error("Error checking video URL:", error);
      }
    },
    async handleSubmit() {
      this.isLoading = true;

      this.paymentStatus = "";

      const amount = 100; // Set your fixed amount for testing

      const response = await fetch(
        `${process.env.VUE_APP_API_URL}/api/create-payment-intent`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ amount }),
        }
      );

      const data = await response.json();
      this.clientSecret = data.clientSecret;

      const { error, paymentIntent } = await this.stripe.confirmCardPayment(
        this.clientSecret,
        {
          payment_method: {
            card: this.cardElement,
          },
        }
      );

      if (error) {
        this.paymentStatus = `Payment failed: ${error.message}`;
        document.getElementById("card-errors").textContent = error.message;
      } else {
        if (paymentIntent.status === "succeeded") {
          this.paymentStatus = "Payment successful! Generating subtitles...";
          const paymentIntentId = paymentIntent.id;
          this.generateSubtitles(this.videoUrl, paymentIntentId);
        } else {
          this.paymentStatus =
            "Payment processing, please check your email for confirmation.";
        }
      }
    },
    async generateSubtitles(videoUrl, paymentIntentId) {
      console.log(videoUrl);
      console.log(paymentIntentId);
      // this.isLoading = true;

      // const response = await fetch(
      //   "http://localhost:3000/api/generate-subtitle",
      //   {
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //     body: JSON.stringify({
      //       videoUrl,
      //       paymentIntentId,
      //       selectedLanguage: this.selectedLanguage,
      //       name: this.name,
      //       customerName: this.customerName,
      //       email: this.email,
      //     }),
      //   }
      // );

      await new Promise((resolve) => setTimeout(resolve, 3000)); // Replace with your API call

      const response = { subtitle: "xxxx" };

      const data = response;
      // const data = await response.json();

      if (data.subtitle) {
        this.isLoading = false;
        this.subtitles = data.subtitle;
      } else {
        this.isLoading = false;
        this.paymentStatus = `Subtitle generation failed: ${data.error}`;
      }
    },
  },
};
</script>

<style scoped>
.subtitle-page {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #282828;
  padding: 10px 20px;
}

.navbar ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navbar li {
  margin-left: 20px;
}

.navbar a {
  text-decoration: none;
  color: white;
  font-weight: bold;
}

.subtitle-section {
  padding: 40px;
  text-align: center;
}

h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #ff0000; /* YouTube red */
}

.subtitle-form {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 30px;
  display: inline-block;
  max-width: 500px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 20px;
  text-align: left;
}

.label {
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
}

.input-field {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s;
}

.input-field:focus {
  border-color: #ff0000; /* YouTube red */
  outline: none;
}

.submit-button {
  background-color: #ff0000;
  color: white;
  border: none;
  padding: 12px 20px;
  font-size: 1em;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  width: 100%;
  transition: background-color 0.3s;
}

/* Disabled button style */
.submit-button:disabled {
  background-color: #cccccc; /* Light gray */
  color: #999999; /* Darker gray for text */
  cursor: not-allowed; /* Change cursor to indicate disabled */
  opacity: 0.6; /* Make the button less opaque */
}

.submit-button:hover {
  background-color: #cc0000;
}

.error-message {
  color: red;
}

.payment-status {
  margin-top: 20px;
}

.subtitles {
  margin-top: 20px;
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.footer {
  background-color: #282828;
  color: white;
  text-align: center;
  padding: 20px 0;
  margin-top: 50px;
}

.footer a {
  color: #ff0000; /* YouTube red */
  text-decoration: none;
  font-weight: bold;
}

.logo {
  height: 50px;
}

/* Keyframe animation for blinking effect */
@keyframes flash {
  0%,
  100% {
    opacity: 1; /* Fully visible */
    border-color: #ff0000; /* Red border */
  }
  50% {
    opacity: 0.5; /* Semi-transparent */
    border-color: #ffcccc; /* Lighter red border */
  }
}

/* Flashing class to be applied conditionally */
.flash {
  animation: flash 1s infinite; /* Apply the flash animation */
}

/* Input field styling */
.input-field {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ff0000; /* Red border */
  border-radius: 5px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s;
}

.error-message {
  color: red; /* Customize as needed */
  font-size: 0.9em; /* Optional: smaller font for error messages */
}

.input-field.error {
  border-color: red; /* Change border color to red */
}

.loading-message {
  color: green; /* Customize as needed */
  font-size: 1.1em;
  margin-top: 10px; /* Space between button and message */
  display: flex; /* Use flexbox for alignment */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  flex-direction: column; /* Stack elements vertically */
}

.spinner {
  border: 5px solid rgba(255, 255, 255, 0.3); /* White border with transparency */
  border-top: 5px solid #ff0000; /* Red border for the top */
  border-radius: 50%; /* Circular shape */
  width: 20px; /* Spinner size */
  height: 20px; /* Spinner size */
  animation: spin 1s linear infinite; /* Spinner animation */
  margin-top: 5px; /* Space between text and spinner */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
