// src/router/index.js
import { createRouter, createWebHistory } from "vue-router";
import HomePage from "@/components/HomePage.vue"; // Make sure to replace with your actual component path
import PricingPage from "@/components/PricingPage.vue"; // Make sure to replace with your actual component path
import FreeSubtitlePage from "@/components/FreeSubtitlePage.vue";
import SubtitleViewPage from "@/components/SubtitleViewPage.vue";
import SubtitleGenerator from "@/components/SubtitleGenerator.vue";

// SubtitleGenerator.vue

const routes = [
  {
    path: "/",
    name: "HomePage",
    component: HomePage,
    meta: { title: "Joe Youtube Subtitle" },
  },
  {
    path: "/pricing",
    name: "PricingPage",
    component: PricingPage,
    meta: { title: "Joe Youtube Subtitle" },
  },
  {
    path: "/free-subtitle",
    name: "FreeSubtitlePage",
    component: FreeSubtitlePage,
    meta: { title: "Joe Youtube Subtitle" },
  },
  {
    path: "/subtitle-view",
    name: "SubtitleViewPage",
    component: SubtitleViewPage,
    meta: { title: "Joe Youtube Subtitle" },
  },

  {
    path: "/subtitle-generator",
    name: "SubtitleGenerator",
    component: SubtitleGenerator,
    meta: { title: "Joe Youtube Subtitle" },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
